@import 'vanilla-cookieconsent/dist/cookieconsent.css';
@import 'prismjs/themes/prism-tomorrow.css';
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    @apply bg-slate-200;
}

.handle {
    cursor: grab;
}

.alert {
    @apply p-4 mt-4 mb-4;
}

.alert-error {
    @apply bg-red-300 text-red-800;
}

.alert-success {
    @apply bg-green-300 text-green-800;
}


/** Course Notes **/
.markdown-container h1 {
    @apply text-3xl mt-4 mb-4;
}

.markdown-container h2 {
    @apply text-2xl mt-4 mb-4;
}

.markdown-container h3 {
    @apply text-xl mt-4 mb-4;
}

.markdown-container p {
    display: block;
    margin: 1em 0;
}

.markdown-container ul {
    list-style-type: disc;
    list-style-position: inside;
}

.markdown-container li {
    display: list-item;
}

.markdown-container ol {
    list-style-type: decimal;
    list-style-position: inside;
}

.markdown-container ul ul, .markdown-container ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 10px;
}

.markdown-container ol ol, .markdown-container ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 10px;
}

.markdown-container table {
    display: table;
    border-collapse: collapse;
    border-color: gray;
    border-width: 1px;
}

.markdown-container table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

.markdown-container table td, .markdown-container table th {
    display: table-cell;
    vertical-align: inherit;
    border-color: gray;
    border-width: 1px;
    padding: 5px;
}

.markdown-container table th {
    font-weight: bold;
    text-align: center;
}

.markdown-container table tfoot {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
}

.markdown-container table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.markdown-container a {
    @apply text-black underline hover:text-blue-600;
}

.markdown-container a:hover {
    @apply no-underline;
}

.tutorial-list, .tutorial-player-container {
    max-height: 720px;
    overflow: hidden;
}
.temp-test {
    width: 100%;
    height: 100%;
}

.short-code a.btn{
    text-decoration: none;
    color: white;
}

.short-code h2 {
     margin-top:0;
}
.alert-blue{
    background-color: #7bbcd5;
}
code:not([class^='language-']) {
    background: #2d2d2d;
    @apply p-1 text-xs rounded text-slate-400;

}
.lds-ring {
    /* change color here */
    color: #db2777;
  }
  .lds-ring,
  .lds-ring div {
    box-sizing: border-box;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 4px;
    border: 4px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }